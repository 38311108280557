<template>
  <section class="admin-overview">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>{{ `${$t('langkey.welcome-back')}, ${$t('langkey.' + userInformation.gender)} ${userInformation.lastname}!` }}</h1>
        <p class="description">
          {{ $t('langkey.admin-overview-description') }}
        </p>
        <v-divider />
        <div class="address">
          <p class="name">
            {{ userInformation.business.name }}
          </p>
          <p class="details">
            {{ userInformation.business.address }}<br>
            {{ userInformation.business.postcode }} {{ userInformation.business.city }}
          </p>
        </div>
        <p
          class="request"
          v-html="$t('langkey.request-account-info-change')"
        />
      </div>
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-overview.scss";
</style>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      userInformation: (state) => state.user.userInformation,
    }),
  },
  created() {
    if (this.userInformation === null) {
      this.$store.dispatch('user/getUserInformation');
    }
  },
};
</script>
